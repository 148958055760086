<!--轮播管理-->
<template>
    <div class="tableBox" style="paddingTop: 30px;">
        <Editor :value="form.content" height="400px" @handEditor="handEditor"></Editor>
        <div style="padding:100px 50px 0" class="flex flex-jc-c">
            <a-button block type="primary" @click="saveAbout" style="width:30%">保存</a-button>
        </div>
    </div>
</template>
<script>
import { InboxOutlined } from '@ant-design/icons-vue';
import { defineComponent, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';
import Editor from '@/components/Editor';
export default defineComponent({
    components: {
        InboxOutlined,
        Editor,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const initData = reactive({
            form: {
                code: 'about_us'
            },
        });
        onMounted(() => {
            AboutList();
        });
        const AboutList = () => {
            proxy.http.post('/admin.system/read', { code: 'about_us' }).then(response => {
                initData.form.content = response.content;
            })
        };

        //获取文章内容
        const handEditor = (event) => {
            initData.form.content = event;
        }

        const saveAbout = () => {
            proxy.http.post('/admin.system/save', initData.form).then(() => {
                proxy.message.success('保存成功');
                NoticeList();
            })
        }
        return {
            ...toRefs(initData),
            AboutList,
            saveAbout,
            handEditor
        };
    },
});
</script>
<style lang="less" scoped>
</style>